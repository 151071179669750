.v-sThumbnail {
	order: 1;
	margin: -1em 0 1em 0;
	display: inline-block;
	vertical-align: middle;

	.v-sectionCard &{
		margin: 0 auto;
		display: block;
	}

	@media only screen and (min-width: 40em) {
		max-width: 15.625em;
		margin: 0 .75em 0 0;
		order: initial;
		width: initial;
	}
	svg{
		width: 100%;
		height: auto;
    		vertical-align: middle;
	}
}
