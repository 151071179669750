/*
.
.     Tooltips 
.      
*/

/* Tooltip container */
[class*="tooltip--"] {
  position: relative;
  display: inline-block;
  /**
	 * tooltip arrow
	 */
  /**
	 * tooltip body
	 */
}
[class*="tooltip--"]:before,
[class*="tooltip--"]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}
[class*="tooltip--"]:hover:before,
[class*="tooltip--"]:hover:after {
  visibility: visible;
  opacity: 1;
}
[class*="tooltip--"]:hover:before,
[class*="tooltip--"]:hover:after {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms;
}
[class*="tooltip--"]:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001;
}
[class*="tooltip--"]:after {
  background: #383838;
  color: white;
  padding: 8px 10px;
  font-size: 1em;
  font-family: "FuturaPTMedium", "PT Sans", sans-serif;
  line-height: 12px;
  white-space: nowrap;
}
[class*="tooltip--"][aria-label]:after {
  content: attr(aria-label);
}
[class*="tooltip--"][data-tooltip]:after {
  content: attr(data-tooltip);
}

[aria-label=""]:before,
[aria-label=""]:after,
[data-tooltip=""]:before,
[data-tooltip=""]:after {
  display: none !important;
}

.tooltip--top-left:before {
  border-top-color: #383838;
}

.tooltip--top-right:before {
  border-top-color: #383838;
}

.tooltip--top:before {
  border-top-color: #383838;
}

.tooltip--bottom-left:before {
  border-bottom-color: #383838;
}

.tooltip--bottom-right:before {
  border-bottom-color: #383838;
}

.tooltip--bottom:before {
  border-bottom-color: #383838;
}

.tooltip--left:before {
  border-left-color: #383838;
}

.tooltip--right:before {
  border-right-color: #383838;
}

/**
   * top tooltip
   */
.tooltip--top:before {
  margin-bottom: -11px;
}

.tooltip--top:before,
.tooltip--top:after {
  bottom: 100%;
  left: 50%;
}

.tooltip--top:before {
  left: calc(50% - 6px);
}

.tooltip--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.tooltip--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

/**
   * bottom tooltip
   */
.tooltip--bottom:before {
  margin-top: -11px;
}

.tooltip--bottom:before,
.tooltip--bottom:after {
  top: 100%;
  left: 50%;
}

.tooltip--bottom:before {
  left: calc(50% - 6px);
}

.tooltip--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.tooltip--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}

/**
   * right tooltip
   */
.tooltip--right:before {
  margin-left: -11px;
  margin-bottom: -6px;
}

.tooltip--right:after {
  margin-bottom: -14px;
}

.tooltip--right:before,
.tooltip--right:after {
  left: 100%;
  bottom: 50%;
}

.tooltip--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.tooltip--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

/**
   * left tooltip
   */
.tooltip--left:before {
  margin-right: -11px;
  margin-bottom: -6px;
}

.tooltip--left:after {
  margin-bottom: -14px;
}

.tooltip--left:before,
.tooltip--left:after {
  right: 100%;
  bottom: 50%;
}

.tooltip--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.tooltip--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

/**
   * top-left tooltip
   */
.tooltip--top-left:before {
  margin-bottom: -11px;
}

.tooltip--top-left:before,
.tooltip--top-left:after {
  bottom: 100%;
  left: 50%;
}

.tooltip--top-left:before {
  left: calc(50% - 6px);
}

.tooltip--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.tooltip--top-left:after {
  margin-left: 12px;
}

.tooltip--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.tooltip--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}

/**
   * top-right tooltip
   */
.tooltip--top-right:before {
  margin-bottom: -11px;
}

.tooltip--top-right:before,
.tooltip--top-right:after {
  bottom: 100%;
  left: 50%;
}

.tooltip--top-right:before {
  left: calc(50% - 6px);
}

.tooltip--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.tooltip--top-right:after {
  margin-left: -12px;
}

.tooltip--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.tooltip--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

/*
   * bottom-left tooltip
   */
.tooltip--bottom-left:before {
  margin-top: -11px;
}

.tooltip--bottom-left:before,
.tooltip--bottom-left:after {
  top: 100%;
  left: 50%;
}

.tooltip--bottom-left:before {
  left: calc(50% - 6px);
}

.tooltip--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.tooltip--bottom-left:after {
  margin-left: 12px;
}

.tooltip--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.tooltip--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}

/**
   * bottom-right tooltip
   */
.tooltip--bottom-right:before {
  margin-top: -11px;
}

.tooltip--bottom-right:before,
.tooltip--bottom-right:after {
  top: 100%;
  left: 50%;
}

.tooltip--bottom-right:before {
  left: calc(50% - 6px);
}

.tooltip--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.tooltip--bottom-right:after {
  margin-left: -12px;
}

.tooltip--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.tooltip--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

/*
 * sizes
 */
.tooltip--small:after,
.tooltip--medium:after,
.tooltip--large:after {
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word;
}

.tooltip--small:after {
  width: 80px;
}

.tooltip--medium:after {
  width: 150px;
}

.tooltip--large:after {
  width: 300px;
}
