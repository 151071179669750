.c-toggle {
  cursor: pointer;
  width: 12em;
  height: 1.75em;
  display: inline-block;
  background: #fff;
  position: relative;
  transition: background-color 0.2s;
  border-radius: 12px;
  &--disabled {
    background: gray;
    cursor: not-allowed;
  }
  &__button {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 50%;
    height: 1.5em;
    transition: 0.2s;
    // background: rgba(0, 0, 0, 0.65);
    border-radius: 10px;
    box-shadow: 1px 2px 4px grey;
    border: 2px solid darkgreen;
  }
  &__checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }
  &__labels {
    position: relative;
    width: 100%;
    z-index: 0;
    height: 100%;
    span {
      color: #353535;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
    }
    span:first-child {
      left: 0;
    }
    span:last-child {
      right: 0;
    }
  }
}
.c-toggle__checkbox:checked + .c-toggle__button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.c-toggle__checkbox:active + .c-toggle__button {
  width: 60px;
}
