.c-swatch {
  display: inline-block;
  width: 100%;
  max-width: 100px;
  font-size: 0.75em;
  margin-right: 1em;
  vertical-align: top;
  margin-bottom: 1em;

  &__ {
    &display {
      display: flex;
      height: 100px;
      font-size: initial;
      margin-bottom: 0.5em;

      > * {
        margin: 0;
      }
    }
  }

  &-- {
    &slowAnimation {
      .c-swatch__display {
        div {
          height: 0%;
          width: 0%;
          transition: height 480ms, width 480ms;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          div {
            height: 90%;
            width: 90%;
          }
        }
      }
    }
    &normalAnimation {
      .c-swatch__display {
        div {
          height: 0%;
          width: 0%;
          transition: height 350ms, width 350ms;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          div {
            height: 70%;
            width: 70%;
          }
        }
      }
    }

    &fastAnimation {
      .c-swatch__display {
        div {
          height: 0%;
          width: 0%;
          transition: height 240ms, width 240ms;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          div {
            height: 50%;
            width: 50%;
          }
        }
      }
    }
  }
}
@keyframes slowAnimation {
  from {
    height: 90%;
    width: 90%;
  }
  to {
    height: 0%;
    width: 0%;
  }
}

@keyframes normalAnimation {
  from {
    height: 70%;
    width: 70%;
  }
  to {
    height: 0%;
    width: 0%;
  }
}

@keyframes fastAnimation {
  from {
    height: 50%;
    width: 50%;
  }
  to {
    height: 0%;
    width: 0%;
  }
}
