@mixin btnStates($button) {
  @each $state, $properties in $button {
    @if $state== "hover" {
      &:#{$state},
      &:active,
      &:focus {
        @each $property, $value in $properties {
          #{$property}: $value;
        }
        svg {
          fill: map-get($properties, "color");
          margin: 0 0.5em;
          &:first-child {
            margin: 0 0.5em 0 0;
          }
          &:last-child {
            margin: 0 0 0 0.5em;
          }
          &:only-child {
            margin: 0 0.5em;
          }
        }
      }
    } @else {
      @each $property, $value in $properties {
        #{$property}: $value;
      }
      svg {
        fill: map-get($properties, "color");
        margin: 0 0.5em;
        &:first-child {
          margin: 0 0.5em 0 0;
        }
        &:last-child {
          margin: 0 0 0 0.5em;
        }
        &:only-child {
          margin: 0 0.5em;
        }
      }
    }
  }
}
