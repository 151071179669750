.c-editContent {
  width: auto;
  color: black;
  display: flex;
  flex-flow: column;
  &__card {
    border-radius: 4px;
    margin-bottom: 0.5em;
    padding: 0.5em 1em;
    display: grid;
    align-items: center;
    border: 1px solid #777;
    background: #252525;
    break-inside: avoid;
    .c-editContent__field {
      display: flex;
      border: 1px solid #444444;
      input[type="text"] {
        height: 2em;
        padding-left: 0.5em;
        border: none;
        border-radius: 2px;
        width: 400px;
        @media screen and (max-width: 32em) {
          width: 318px;
        }
      }
    }
  }
  &__field {
    border-radius: 4px;
    margin-bottom: 0.5em;
    padding: 0.5em 1em;
    display: flex;
    break-inside: avoid;
    align-items: center;
    border: 1px solid #777;
    background: #252525;
    input[type="checkbox"] {
      margin-right: 0.5em;
    }
    input[type="text"] {
      height: 2em;
      padding-left: 0.5em;
      border: none;
      border-radius: 2px;
      width: 400px;
      @media screen and (max-width: 32em) {
        width: 318px;
      }
    }
  }
  span {
    color: white;
  }
  @media screen and (max-width: 32em) {
    .l-1\/2 {
      width: 100%;
    }
    .u-flex1 {
      flex: none !important;
    }
  }
}

////@at-root
