.u-font-myriadpro,
.u-font-myriadPro {
  font-family: "myriad-pro", sans-serif !important;
}

.u-font-breuer {
  font-family: "breuer text", sans-serif !important;
}

// loop creates:
// .u-font-weight### from 100 - 900
@for $i from 1 through 9 {
  .u-font-weight#{$i}00 {
    font-weight: #{$i}00 !important;
  }
}
