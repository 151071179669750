/* ======================================== */
/* =                                      = */
/* =               Normalize              = */
/* =                                      = */
/* ======================================== */

/*
.
.     Normalize CSS for HTML elements:
.     Document | Section/Text Block | Inline | Media | Presentation | Form | Table
.      
*/

// - - - - - - - - - -
// * Document
// - - - - - - - - - -
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
}

// - - - - - - - - - -
// * Sections / Text Block Elements
// - - - - - - - - - -
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
address {
  font-style: normal;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}

// - - - - - - - - - -
// * Inline Text Elements
// - - - - - - - - - -
a {
  background-color: transparent;
  &:active,
  &:hover {
    outline: 0;
  }
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: $font-1-weight-bold;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 0.75em;
}
sub,
sup {
  font-size: 0.5em;
  line-height: 0;
  position: relative;
  vertical-align: super;
  padding: 0 0.25em;
}
sup {
  top: 0;
}
sub {
  bottom: -0.25em;
}

// - - - - - - - - - -
// * Media Elements
// - - - - - - - - - -
img {
  border: 0;
  width: auto;
  height: auto;
  max-width: 100%;
}
svg {
  shape-rendering: geometricPrecision;
  &:not(:root) {
    overflow: hidden;
  }
}
figure {
  margin: 1em 40px;
}
iframe {
  border: 0;
  width: 100%;
}

// - - - - - - - - - -
// * Presentation Elements
// - - - - - - - - - -
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: "Source Code Pro", monospace;
  font-size: 0.875em;
  font-weight: 200;
  white-space: pre-wrap;
}

// - - - - - - - - - -
// * Form Elements
// - - - - - - - - - -
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background: none;
  border: 0;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type="text"],
input[type="search"],
input[type="number"],
textarea,
select {
  display: block;
  width: 100%;
}
select[disabled] {
  opacity: 0.6;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: $font-1-weight-bold;
}

// - - - - - - - - - -
// * Table Elements
// - - - - - - - - - -
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
