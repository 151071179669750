.slick-slider {
  margin-left: -42px;
  width: calc(100% + 84px);
}
.slick-arrow {
  color: #c8102e;
}
.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  &:before {
    font-size: 30;
  }
}

.slick-prev {
  left: -40px;
}
.slick-next {
  right: -40px;
}
.slick-arrow:before {
  color: #b9102a;
}
.slick-arrow:enabled:before {
  color: #b9102a;
}
.slick-arrow:hover:disabled {
  color: #ccc;
}
