.s-headline {
  background: #252525; //Replace w/ Color variable
  font-family: "proxima-nova", sans-serif;
  text-align: center;
  padding: 2.5em 1em 1.875em;
  z-index: 1000;
  &__breadcrumbs {
    font-size: 0.875em;
    margin: 0 0 0.5em;
    &__slash {
      margin: 0.5em;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    gap: 1em;

    &__copy {
      margin-bottom: 0;
    }
    &__tags {
      display: flex;
      &__tag {
        display: block;
        font-size: 0.75rem;
        color: black;
        background-color: white;
        padding: 0.125em 0.75em;
        border-radius: 1em;
        text-transform: uppercase;
        margin: 0.25em;
      }
    }
  }
  &__description {
    margin-bottom: 0;
  }
  &__googleDoc,
  &__related {
    border-top: 1px solid #585858;
    padding-top: 1em;
    margin-top: 1em;
    align-items: center;
    font-size: 0.875em;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        position: relative;
        width: 14.375rem;
        a {
          color: #fff;
          line-height: 1.2;
          background-color: #1b1b1b;
          padding: 1em 1.25em;
          border-radius: 0.75em;
          transition: background-color 350ms ease-out;
          display: flex;
          gap: 0.5em;
          &:hover,
          &:focus {
            background-color: #3c3c3c;
          }
        }
      }
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: white;
    }
  }
  &__related {
    border-top: none;
    padding-top: 0;
    ul {
      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(4, 1fr);
      li {
        &::after {
          content: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 6a.786.786 0 01-.217.547l-4.588 4.741a.672.672 0 01-.998-.02.799.799 0 01.02-1.073L4.276 6 .216 1.805A.8.8 0 01.198.733a.672.672 0 01.998-.021l4.588 4.741c.139.143.217.34.217.547' fill='%23FFF' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
          position: absolute;
          top: 48%;
          right: 0.25em;
          width: 1em;
          height: 1em;
          transform: translateY(-50%);
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  &-- {
    &alignLeft {
      text-align: left;
      padding: 2.5em 0 1.875em;
      .s-headline__title {
        margin: 0;
      }
    }
  }
}
