.v-dropDown {
  min-width: 15em;
  text-align: left;
  display: inline-block;
  width: auto;
  background: $v-toggleBg;
  border-radius: 500px;

  &__ {
    &icon {
      fill: white;
      vertical-align: baseline;
      margin: 0 0.375em 0 0;
    }
    &chevron {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-45%);
      transform-origin: center;
      width: 1em;
      .c-dropDown--active & {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .c-dropDown__toggler {
    line-height: normal;
    padding: 0.35em 2.75em 0.35em 1em;
    min-height: 1.65rem;
  }
  .c-dropDown__panel {
    background: $v-toggleBg;
    margin-top: 0.25em;
    border-radius: 12px;
    padding: 0 0.125em 0 0;
    min-width: 15em;
    width: max-content;
    > ul {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: white;
      }
      &::-webkit-scrollbar-corner {
        background-color: #323232;
      }
      max-height: 7em;
      li {
        padding: 0.25em 0 0.25 1em;
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          background: $v-mainBg;
        }
        &[data-selected] {
          display: none;
        }
      }
    }
  }
}
