.s-mosaicMenu {
  display: flex;
  padding: 2em 0;
  max-width: 75em;
  perspective: 1000px;
  perspective-origin: center;
  &__row {
    display: flex;
    flex-direction: column;
    @include media-lg {
      flex-direction: row;
      &:nth-child(2) {
        flex: 1;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  &__col {
    display: flex;
    position: relative;
    background: #3b444c;
    border-radius: 2px;
    margin-bottom: 1em;
    min-height: 20em;
    align-items: flex-end;
    padding-left: 1.5em;
    transition: transform 250ms;
    @include media-lg {
      margin-bottom: 0;
      margin-right: 1em;
    }
    &:last-child {
      margin-bottom: 0;
      @include media-lg {
        margin-right: 0;
      }
    }
    &__title {
      z-index: 100;
      color: white;
    }
    &__bg {
      position: absolute;
      max-width: none;
      left: 0;
      bottom: 0;
      transform: none;
      transition: transform 0.35s;
      transition-timing-function: ease-out;
      transform-origin: center;
    }
    &:hover {
      color: #addfff;
      text-decoration: none;
      z-index: 999;
      .s-mosaicMenu__col__bg {
        transform: scale(1.05);
      }
    }
  }
  &__post {
    margin-top: 0.5em;
    ul {
      margin-top: 1em;
      justify-content: flex-start;
      li {
        margin: 0 1.5em;
        img {
          margin-right: 0.5em;
          transition: transform 350ms ease-out;
        }
        a {
          color: white;
          display: flex;
          align-items: center;
          &:hover {
            color: #93d5ff;
            text-decoration: underline;
            img {
              transform: translateX(4px);
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    svg {
      fill: white;
      width: 1em;
      height: 1em;
      margin-right: 0.25em;
    }
  }
}
