.v-specs {
  position: relative;
  color: white;
  [data-themeable="true"] {
    &::after {
      content: "- Themeable";
      display: inline-block;
      padding-left: 0.5em;
      font-size: 1rem;
      font-weight: normal;
      color: #999;
    }
  }

  // Children
  &__ {
    &data {
      padding: 0.75em 2em;
      display: flex;
      align-items: center;
      border: 1px solid #444444;
      border-bottom: 0;
      background: #252525;
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-bottom: 1px solid #444444;
        border-radius: 0 0 4px 4px;
      }
      &__ {
        &key {
          width: 8em;
          padding-right: 3em;
          border-right: 1px solid #6f6f6f;
        }
        &content {
          flex: 1;
          .l-row__col {
            padding-left: 2em;
            padding-right: 0.5em;
            border-right: 1px solid #6f6f6f;
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  // Modifiers
  &-- {
    &component {
      &.v-specs--active {
        background-color: transparentize($vSpecs-componentColor, $vSpecs-transparency);
      }
    }
    &layout {
      &.v-specs--active {
        background-color: transparentize($vSpecs-layoutColor, $vSpecs-transparency);
      }
    }
    &highlight {
      &:not(.s-promo__content, .s-keyFeature__img) {
        position: relative;
        box-shadow: inset 0px 0px 0px 1px #75cbe6;
      }
      &[data-img-size] {
        &:after {
          content: attr(data-img-size);
          position: absolute;
          display: block;
          bottom: 0;
          right: 0;
          margin: 0.5em;
          background: rgb(50, 50, 50);
          color: white;
          border-radius: 500px;
          padding: 0.2em 1.75em;
          font-family: "myriad-pro", sans-serif;
          z-index: 120;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(3, 207, 252, 0.4);
        z-index: 100;
      }
    }
    &cis {
      margin-top: 2em;
    }
  }
}
