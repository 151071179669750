.c-iconCard {
  overflow: hidden;
  border-radius: 0.5em;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  transition: background-color 250ms, transform 250ms, opacity 250ms;

  &:hover {
    background-color: black;
    border-color: #666;
    transform: scale(1.25);
    svg {
      fill: white !important;
    }
  }

  svg {
    display: block;
    margin: auto;
    padding: 1em;
    width: 100%;
    height: 5em;
    transition: fill 250ms;
  }

  &-- {
    &loading {
      opacity: 0.25;
    }
  }
}
