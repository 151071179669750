.code-tag {
    color: #8e8e8e;
}
.code-name {
    color: #e69fa1;
}
.code-attr {
    color: #eac374;
}
.code-string {
    color: #9ac6f3;
}