/* ======================================== */
/* =                                      = */
/* =             List Utilities           = */
/* =                                      = */
/* ======================================== */

/*
.
.     Defines utilities used to modify list elements
.     ! All list utilities must be prefixed with .u-list
.
*/

// - - - - - - - - -
// * List Style 
// usage:  -square || -horizontal || -unstyled
// - - - - - - - - -
.u-list-unstyled {
    list-style: none;
}
.u-list-horizontal{
    > * { 
        display: inline-block; 
    }      
}
.u-list-square {
    list-style-type: square;
}
