.s-iconMap {
  background-color: #252525;
  // height: 90vh;
  overflow: auto;
  display: block;
  &__ {
    &navbar {
      border-top: 1px solid #777;
      position: sticky;
      top: 76px;
      z-index: 100;

      &__ {
        &search {
          display: flex;
          flex-flow: row;
          justify-content: center;
          padding: 0.625rem 0 0.625rem;
          max-width: 100%;
          margin: 0px;
          background-color: #141514;
          .c-search {
            padding: 0;
            display: flex;
            width: 21rem;
            input.c-search__input {
              width: 21rem;
              font-size: 1rem;
              max-width: 21rem;
              margin: 0 -4px 0 0;
              border-radius: 0.25rem 0 0.25rem 0.25rem;
              border: 0;
              padding: 0.25rem 1.5rem;
              box-shadow: inset 0.075em 0.075em 0.075em rgba(0, 0, 0, 0.4);
              background-color: white;
              color: black;

              &:focus,
              &:active {
                box-shadow: inset 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.6);
                outline: 0;
              }
            }
            .c-search__icon {
              border-radius: 5px 5px 5px 0;
              position: absolute;
              right: 0;
              transition: fill 350ms;
              width: 2em;
              height: 2em;
              position: absolute;
              right: 0;
              transition: fill 350ms;
              display: flex;
              align-content: center;
              justify-content: center;
              position: relative;
              flex-direction: column;
              img {
                color: white;
                min-width: 1.25em;
              }
            }
          }
          .c-search--active {
            border-bottom: none;
          }
        }
        &links {
          height: auto;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          padding: 0.25rem 0 0.25rem;
          justify-content: space-around;
          line-height: 2rem;
          background-color: #323232;
          a,
          a:visited {
            margin: 1px 0.5rem;
          }
        }
      }
    }
    &layout {
      padding: 2rem 1rem 10rem;
      width: 100%;
      min-height: 50vh;
      transition:
        width 500ms,
        opacity 500ms;
      grid-area: layout;

      overflow-y: auto;

      h3 {
        padding-bottom: 1rem;
      }
      h4 {
        padding: 0;
        scroll-margin-top: 200px;
        // &[id="buttons"] {
        //   &::before {
        //     content: "";
        //     display: block;
        //     height: 600px;
        //     margin-top: -600px;
        //     visibility: hidden;
        //   }
        // }
      }

      button {
        margin: 3rem 0 0 0;
      }
      &__title__tags {
        display: flex;
        span {
          color: white;
          text-transform: uppercase;
          border-radius: 1rem;
          margin: 0.25em 0 0 1em;
          padding: 0.125em 0.75em;
          font-size: 0.75rem;
          display: block;
        }
      }
      p {
        margin: 0 0 1.5rem;
      }
      &__grid {
        display: grid;
        grid-gap: 1rem;
        justify-content: left;
        max-width: 100%;
        margin: 0 0 4rem 0;
        padding: 1rem;
        list-style: none;
        grid-template-columns: repeat(auto-fit, 4.875rem);

        li {
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 0.5rem;
          position: relative;
          &:hover {
            cursor: pointer;
          }
          &::before {
            content: attr(data-index);
            position: absolute;
            top: 0;
            left: 0;
            line-height: 1;
            color: white;
            background: black;
            border-radius: 0.5rem 0 0.5rem 0;
            font-size: 0.75rem;
            padding: 0.125rem;
          }

          .c-iconCard {
            svg {
              height: 4.875rem;
            }
          }
        }
        &--results {
          padding: 1rem 0;
        }
      }
    }
  }

  &-- {
    &active,
    &loading {
      .s-iconMap__layout,
      .s-iconMap__search {
        width: 70vw;
      }
    }
    &empty {
      .s-iconMap__layout {
        &__grid {
          display: none;
        }
      }
    }
  }
}
.ReactModal__Content.ReactModal__Content--after-open {
  z-index: 1000;
  display: flex;
  flex-flow: column;
  min-width: 20%;
  svg {
    width: 7em;
  }
  button {
    display: flex;
    // width: fit-content;
    svg {
      width: 1.5rem;
      margin-top: 0.25rem;
    }
  }
  a {
    &:link {
      color: #2263b2;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  .tagBtn {
    background-color: white;
    backdrop-filter: blur(4px);
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: #353535;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0.375em 1.25em 0.5em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition:
      background-color 150ms ease-in,
      border-color 150ms ease-in;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: 0;
    span {
      font-size: 1.25rem;
      width: 100%;
      margin: 0;
      // font-weight: bold;
    }
    &:hover {
      transition:
        background-color 150ms ease-out,
        border-color 150ms ease-out;
      color: white;
      background-color: black;
    }
  }
}
