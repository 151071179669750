/* ======================================== */
/* =                                      = */
/* =           Box Model Utilities        = */
/* =                                      = */
/* ======================================== */

/*
.
.     Defines all utilities that relate to the CSS Box Model: Margins / Borders / Padding / Content Box
.      
*/

// - - - - - - - - - -
// * Keylines / Borders
// prefix: u-keyline
// usage:  -top | -right | -bottom | -left
// - - - - - - - - - -
.u-keyline {
      &-top,&-right,&-bottom,&-left{
            border-style: solid;
            border-color: $silver;
      }
      &-top{ border-width: 1px 0 0 0; }
      &-right{ border-width: 0 1px 0 0; }
      &-bottom{ border-width: 0 0 1px 0; }
      &-left{ border-width: 0 0 0 1px; }
}

// - - - - - - - - - -
// * Margin / Padding
// - - - - - - - - - -
$global-margin-sm : 1.25em;
$global-margin-md : 1.875em;
$global-margin-lg : 3.125em;

$global-padding-sm : 1.25em;
$global-padding-md : 1.875em;
$global-padding-lg : 3.125em;

.u-m { 
      margin: $global-margin-sm !important;
      @include media-md{ margin: $global-margin-md !important; }
      @include media-lg{ margin: $global-margin-lg !important; }
}
.u-mt { 
      margin-top: $global-margin-sm !important; 
      @include media-md{ margin-top: $global-margin-md !important; }
      @include media-lg{ margin-top: $global-margin-lg !important; }
}
.u-mr { 
      margin-right: $global-margin-sm !important; 
      @include media-md{ margin-right: $global-margin-md !important; }
      @include media-lg{ margin-right: $global-margin-lg !important; }
}
.u-mb { 
      margin-bottom: $global-margin-sm !important; 
      @include media-md{ margin-bottom: $global-margin-md !important; }
      @include media-lg{ margin-bottom: $global-margin-lg !important; }
}
.u-ml { 
      margin-left: $global-margin-sm !important; 
      @include media-md{ margin-left: $global-margin-md !important; }
      @include media-lg{ margin-left: $global-margin-lg !important; }
}

// -- Margin 1em
.u-m1{ margin: 1em !important; }
.u-mt1{ margin-top: 1em !important; }
.u-mr1{ margin-right: 1em !important; }
.u-mb1{ margin-bottom: 1em !important; }
.u-ml1{ margin-left: 1em !important; }

// -- Margin 0
.u-m0 { margin: 0 !important; }
.u-mt0 { margin-top: 0 !important; }
.u-mb0 { margin-bottom: 0 !important; }

// -- Padding
.u-p { 
      padding: $global-padding-sm !important;
      @include media-md{ padding: $global-padding-md !important; }
      @include media-lg{ padding: $global-padding-lg !important; }
}
.u-pt { 
      padding-top: $global-padding-sm !important; 
      @include media-md{ padding-top: $global-padding-md !important; }
      @include media-lg{ padding-top: $global-padding-lg !important; }
}
.u-pr { 
      padding-right: $global-padding-sm !important; 
      @include media-md{ padding-right: $global-padding-md !important; }
      @include media-lg{ padding-right: $global-padding-lg !important; }
}
.u-pb { 
      padding-bottom: $global-padding-sm !important; 
      @include media-md{ padding-bottom: $global-padding-md !important; }
      @include media-lg{ padding-bottom: $global-padding-lg !important; }
}
.u-pl { 
      padding-left: $global-padding-sm !important; 
      @include media-md{ padding-left: $global-padding-md !important; }
      @include media-lg{ padding-left: $global-padding-lg !important; }
}
// -- Padding 0
.u-p0 {
      padding: 0 !important;
}
.u-pt0 {
      padding-top: 0 !important;
}
.u-pb0 {
      padding-bottom: 0 !important;
}

// - - - - - - - - - -
// * Max Height
// - - - - - - - - - -
.u-mxh0{
      max-height: 0 !important;
}


// - - - - - - - - - -
// * Scroll Disabled
// - - - - - - - - - -
.scroll-disabled { overflow: hidden; } 

