@font-face {
  src:
    url("../../static/fonts/breuertext-bold-webfont.woff2") format("woff2"),
    url("../../static/fonts/breuertext-bold-webfont.woff") format("woff");
  font-family: "breuer text", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  src:
    url("../../static/fonts/breuertext-bold-webfont.woff2") format("woff2"),
    url("../../static/fonts/breuertext-bold-webfont.woff") format("woff");
  font-family: "breuer text", sans-serif;
  font-weight: normal;
  font-style: normal;
}

$font-1-family: "myriad-pro", sans-serif;
$font-1-weight-normal: 400;
$font-1-weight-bold: 600;

$font-2-family: "breuer text", sans-serif;
$font-2-weight-normal: 500;
$font-2-weight-bold: 700;

$font-base-size: 1em;
$font-base-lineheight: 1.5;
$font-base-color: #353535;
$font-base-weight: $font-1-weight-normal;

$font-mega-family: $font-2-family;
$font-mega-weight: $font-2-weight-bold;
$font-mega-style: normal;
$font-mega-marginbottom: 0.5rem;

$font-size-xs: 0.875em;
$font-size-sm: 0.937em;
$font-size-base: 1em;
$font-size-md: 1.125em;
$font-size-lg: 1.25em;

$font-lh-base: 1.5;

$heading-color: black;

$heading-font-weight: $font-2-weight-normal;
$subHeading-font-weight: $font-2-weight-normal;

$heading1-fontsize: 2.125em;
$heading1-fontfamily: $font-2-family;
$heading1-font-weight: $heading-font-weight;

$heading2-fontsize: 1.75em;
$heading2-fontfamily: $font-2-family;
$heading2-font-weight: $heading-font-weight;

$heading3-fontsize: 1.5em;
$heading3-fontfamily: $font-2-family;
$heading3-font-weight: $heading-font-weight;

$heading4-fontsize: 1.375em;
$heading4-fontfamily: $font-2-family;
$heading4-font-weight: $heading-font-weight;

$heading5-fontsize: 1.125em;
$heading5-fontfamily: $font-2-family;
$heading5-font-weight: $heading-font-weight;

$heading6-fontsize: 1em;
$heading6-fontfamily: $font-2-family;
$heading6-font-weight: $heading-font-weight;
