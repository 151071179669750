.l-viewerPanels {
  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5em;
    border: 1px solid #444;
    padding: 1.25em;
    @media screen and (max-width: 50em) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 32em) {
      flex-direction: column;
    }

    h6 {
      text-align: center;
    }
    &__option {
      display: flex;
      flex-direction: column;
      padding: 0 1em;

      @media screen and (max-width: 50em) {
        padding-bottom: 2em;
        align-items: center;
      }
    }
  }
}
