.v-tool {
  background: black;
  color: white;
  padding: 1em 1.5em;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
  user-select: none;
  font-size: 0.875rem;
  border-bottom: 1px solid #6c7175;
  &__ {
    &title {
      display: block;
      margin-bottom: 0.5em;
      display: flex;
      align-items: center;
      font-weight: 500;
      min-width: 8.5em;
      @include media-lg {
        margin-bottom: 0;
      }
      svg {
        width: 1.25rem;
        height: auto;
        vertical-align: sub;
        margin-right: 0.75em;
      }
    }
    &opts {
      display: block;
      text-align: left;
      @include media-lg {
        text-align: right;
        display: flex;
        align-items: center;
      }
      > * {
        display: block;
        margin-bottom: 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
        @include media-lg {
          margin-bottom: 0;
          display: inline-block;
        }
      }
      form {
        > * {
          display: block;
          margin-right: 1em;
          &:last-child {
            margin-right: 0;
          }
          @include media-lg {
            display: inline-block;
          }
        }
      }
    }
    &btn {
      display: inline-block;
      cursor: pointer;
      padding: 0.2em 1.75em;
      margin: 0 2.5px 0.35em;
      @include media-lg {
        margin-bottom: 0;
      }
      &:hover {
        border: 1px solid #00c5ff;
        background: white;
        color: black;
      }
      &:disabled {
        border-color: gray;
        color: gray;
        &:hover {
          border-color: gray;
          color: gray;
          cursor: not-allowed;
          background: transparent;
        }
      }
    }
  }
  &-- {
    &modifiers {
      input[type="checkbox"],
      input[type="radio"] {
        margin-right: 3px;
      }
    }
  }
}
#portal-root {
  display: none;
}
