.v-overlay {
	background: black;
	opacity: 0;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 300ms;
}
