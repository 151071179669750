// -- rename test
.c-dropDown {
  $root: &;
  position: relative;

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0 0.625em;
    }
  }

  // Elements
  &__ {
    &toggler {
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
    &panel {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 0;
      transition: max-height 250ms;
      overflow: hidden;
      z-index: 100;

      &__tool {
        padding: 0.625em;
      }
    }
  }

  // Modifiers
  &-- {
    &active {
      #{$root}__panel {
        max-height: 50vh;
      }
    }
  }
}

// LEGACY -- LIVES ONLY IN NAVIGATION.HTML, REPLACE W/ Component.
//   .dropdown {
//       position: relative;
//       max-width: 20rem;
//       margin: 0 auto 1rem;

//       #country-selector {
//           display: block;
//           background: $accent-1;
//           border: none;
//           padding: 0.5rem 1rem;
//           color: $white;
//           border-bottom: 1px solid $accent-1;
//           position: relative;
//           cursor:pointer;
//           .caret {
//               float: right;
//               margin-top: .5rem;
//               border-bottom: none;
//               border-top: 9px solid #fff;
//               border-left: 9px solid $accent-1;
//               border-right: 9px solid $accent-1;
//           }
//       }

//       .slimScrollDiv {
//           display: none;
//           position: absolute !important;
//           width: 100% !important;
//           -ms-touch-action: pan-x pinch-zoom;
//           touch-action: pan-x pinch-zoom;
//       }

//       .dropdown-menu {
//           position: absolute;
//           display: none;
//           padding: 0;
//           background: $accent-1;
//           width: 100% !important;
//           overflow-y: auto;
//           z-index: 98;
//           margin: 0;

//           li {
//               border-bottom: 1px solid #dd7679;
//               padding: 0.5rem 1rem;
//               margin: 0;

//               a {
//                   color: $white;
//                   display: block;

//                   &:hover {
//                       color: $white;
//                   }
//               }
//           }
//       }

//       &.open {
//           #country-selector {
//               background: $accent-1;
//               color: $white;
//               border-bottom: 1px solid #dd7679;

//               .caret {
//                   border-bottom: 9px solid $white;
//                   border-top: none;
//                   border-left: 9px solid $accent-1;
//                   border-right: 9px solid $accent-1;
//               }
//           }

//           .slimScrollDiv,
//           .dropdown-menu {
//               //   Comment
//               display: block;
//           }
//       }
//   }
